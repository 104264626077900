import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ReleaseNote = makeShortcode("ReleaseNote");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <ReleaseNote date="2014-08-04" title="WSDL and Port Change Required for Some minFraud SOAP Users" mdxType="ReleaseNote">
      <p>{`On August 11, we will discontinue serving requests to port 8005. Users of WSDL
version 2 and 4 must take action to ensure continuation of service. We ask
that you upgrade your WSDL:`}</p>
      <ul>

        <li {...{
          "parentName": "ul"
        }}>{`To access the latest inputs and outputs, we recommend you upgrade to our
latest WDSL and upgrade to minfraud version 1.3. Note that the old score and
explanation outputs are no longer supported in the latest WDSL.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`If your system requires score and explanation for backwards compatibility, you
may upgrade to WSDL version 5.`}</li>

      </ul>
      <p>{`In addition, on August 5, we will disable the web services on port 8005 for one
hour starting at 1pm UTC.`}</p>
      <p>{`Please email us at `}
        <a {...{
          "href": "mailto:support@maxmind.com",
          "parentName": "p"
        }}>{`support@maxmind.com`}</a>
        {` if you have any questions.`}</p>
    </ReleaseNote>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      